/* Customizable Area Start */
.rightSideContainer {
  display: flex;
  justify-content: center;

  @media (max-width: 425px) {
    padding: 20px
  }
}

.rightContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 500px;

  @media (max-width: 425px) {
    width: 310px;
  }
}

.errorText {
  color: #DC2626;
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
  width: 370px;
}

.signUpBtn {
  background-color: #0069BC;
  padding: 10px 16px;
  text-transform: none;
  width: 370px;
  height: 60px;
  border-radius: 12px;
  color: #FFFFFF;
  font-size: 20px;
  font-weight: 700;
  margin: 16px 0px;
}

.signUpBtn:hover {
  background-color: #0069BC;
}

.inputMainBox {
  width: 370px;
  height: 48px;
  border-radius: 8px;
  
  @media (max-width: 425px) {
    width: 280px;
  }
}

.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
  width: 100%;
}

/* Customizable Area End */